import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const VacationFormApprovedByStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:vacation.basicInformation")} />
      <Row>
        <Field
          component={BMXSelectField}
          values={[
            { value: "POSITIVE", name: t("global:vacation.positive") },
            { value: "NEGATIVE", name: t("global:vacation.negative") },
          ]}
          label={`${t("global:vacation.HRDecision")}*`}
          name="hrdecision"
          data-cy="hrdecision"
        />

        <Col xs={12}>
          <Field
            name="hrcomments"
            label={`${t("global:vacation.HRComments")}`}
            component={BMXTextField}
            data-cy="hrcomments"
            multiline
            maxRows={4}
          />
        </Col>
      </Row>
    </div>
  );
};

export default VacationFormApprovedByStepOne;
