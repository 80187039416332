import { useEffect, useState } from "react";
import RentContractDeviceFormStepOne from "./RentContractDeviceFormSteps/RentContractDeviceFormStepOne";
import { onSubmit, onValidate } from "./RentContractDeviceFormSubmit";
import { useTheme } from "@emotion/react";
import { Form } from "react-final-form";
import SwipeableViews from "react-swipeable-views";
import RentContractDeviceFormStepTwo from "./RentContractDeviceFormSteps/RentContractDeviceFormStepTwo";

const RentContractDeviceForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update, initialValues: _initialValues } = props;
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (update) {
      setInitialValues({
        ..._initialValues,
        sn: _initialValues?.device?.serialNumber || null,
        // deviceOwner: _initialValues?.owner?.uuid
        //   ? {
        //       ..._initialValues?.deviceOwner,
        //       label: _initialValues?.deviceOwner?.entryName,
        //       uuid: _initialValues?.deviceOwner?.uuid,
        //     }
        //   : null,
        producent:
          _initialValues?.device?.printerModel?.manufacturer?.entryName || null,
        model: _initialValues?.device?.printerModel?.modelName || null,
        rentalCost: _initialValues?.rentalCost || "0",
        producerAndModel: `${
          _initialValues?.device?.printerModel?.manufacturer?.entryName || ""
        } ${_initialValues?.device?.printerModel?.modelName || ""}`,
        deviceOwner: `${_initialValues?.device?.deviceOwner?.entryName || ""} `,
        contractType: {
          value: `${_initialValues?.contractType || "SUBSCRIPTION"}`,
        },
      });
      return;
    }
    setInitialValues({
      rentalCost: "0",
      monitoringCost: "0",
      monoPackageCost: "0",
      colorPackageCost: "0",
      contractType: {
        value: `${_initialValues?.contractType || "SUBSCRIPTION"}`,
      },
    });

    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="RentContractDeviceForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <RentContractDeviceFormStepOne {...formProps} {...props} />
              <RentContractDeviceFormStepTwo {...formProps} {...props} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default RentContractDeviceForm;
