import thunk from "redux-thunk";
import logger from "redux-logger";
import userSesstionReducer from "./Redux/Reducers/UserSession/index";
import { reducer as modal } from "redux-modal";
import errorsReducer from "./Redux/Reducers/ErrorReducer";
import AuthFetchReducer from "./Redux/Reducers/AuthFetchReducer";
import registryReducer from "./Redux/Reducers/RegistryReducer";
import SocketReducer from "./Redux/Reducers/SocketReducer";
import TranslationReducer from "./Redux/Reducers/TranslationReducer";
import carReducer from "./Redux/Reducers/RegistriesReducers/CarReducer";
import workerReducer from "./Redux/Reducers/RegistriesReducers/WorkerReducer";
import roleReducer from "./Redux/Reducers/RoleReducer";
import alertReducer from "./Redux/Reducers/AlertReducer";
import departmentsReducer from "./Redux/Reducers/RegistriesReducers/DepartmentReducer";
import contractReducer from "./Redux/Reducers/RegistriesReducers/ContractReducer";
import contactsReducer from "./Redux/Reducers/RegistriesReducers/ContactReducer";
import branchReducer from "./Redux/Reducers/RegistriesReducers/BranchReducer";
import representativeReducer from "./Redux/Reducers/RegistriesReducers/RepresentativeReducer";
import secretaryEmailReducer from "./Redux/Reducers/SecretaryReducer/SecretaryEmail";
import secretaryCorespondenceReducer from "./Redux/Reducers/SecretaryReducer/SecretaryCorespondence";
import secretaryNoteReducer from "./Redux/Reducers/SecretaryReducer/SecretaryNote";
import deviceReducer from "./Redux/Reducers/RegistriesReducers/DeviceReducer";
import printerModelReducer from "./Redux/Reducers/RegistriesReducers/PrintermodelReducer";
import documentReducer from "./Redux/Reducers/RegistriesReducers/DocumentReducer";
import storageReducer from "./Redux/Reducers/StorageReducer";
import { configureStore } from "@reduxjs/toolkit";
import settingsReducer from "./Redux/Reducers/SettingsReducer";
import offerReducer from "./Redux/Reducers/RegistriesReducers/OfferReducer";
import rentContractReducer from "./Redux/Reducers/RegistriesReducers/RentcontractReducer";
import suppliesManagementReducer from "./Redux/Reducers/RegistriesReducers/SuppliesmanagementReducer";
import releaseSuppliesReducer from "./Redux/Reducers/RegistriesReducers/ReleasesuppliesReducer";
import operationlockReducer from "./Redux/Reducers/RegistriesReducers/OperationlockReducer";
import vacationReducer from "./Redux/Reducers/RegistriesReducers/VacationReducer";
import { env } from "./env";

const DEVELOPMENT = "development";

const reducer = {
  modal,
  auth: AuthFetchReducer,
  userSession: userSesstionReducer,
  errors: errorsReducer,
  registry: registryReducer,
  socket: SocketReducer,
  translation: TranslationReducer,
  roleReducer,
  carReducer,
  workerReducer,
  alertReducer,
  departmentsReducer,
  contactsReducer,
  branchReducer,
  representativeReducer,
  storageReducer,
  secretaryEmailReducer,
  secretaryCorespondenceReducer,
  secretaryNoteReducer,
  settingsReducer,
  contractReducer,
  deviceReducer,
  printerModelReducer,
  documentReducer,
  offerReducer,
  rentContractReducer,
  suppliesManagementReducer,
  releaseSuppliesReducer,
  operationlockReducer,
  vacationReducer,
};

const middleware = [thunk];

if (env?.NODE_ENV === DEVELOPMENT) middleware.push(logger);

export default configureStore({ reducer, middleware: middleware });
