import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";

import "./VacationFormStepOne.scss";

const VacationFormStepOne = (props) => {
  const { t } = useTranslation();
  const [vacationTypeSelect, setVacationTypeSelect] = useState([]);
  const [workersSelect, setWorkersSelect] = useState([]);
  const [departmentsLeadersSelect, setDepartmentsLeadersSelect] = useState([]);


  const { update } = props;


  useEffect(() => {
    // LOADING DICTIONARIS
    const fetchData = async () => {
      const vacationTypeFetch = GlobalAuthFetch({
        path: "/dictionary/vacationType/fieldName",
      });

      const workersFetch = GlobalAuthFetch({ path: "/worker/all" });

      const departmentsLeaders = GlobalAuthFetch({
        path: "/department/getAllDepartmentsManagers",
      });

      const [vacationTypePayload, workersPayload, departmentsLeadersPayload] =
        await Promise.all([
          vacationTypeFetch,
          workersFetch,
          departmentsLeaders,
        ]);
      setVacationTypeSelect(
        vacationTypePayload?.payload?.data?.dictionaryEntries
      );
      setWorkersSelect(workersPayload?.payload?.data);
      setDepartmentsLeadersSelect(departmentsLeadersPayload?.payload?.data);
    };
    fetchData();
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:vacation.basicInformation")} />
      <Row>
        <Col xs={12}>
          <Field
            disabled={update}
            component={BMXSelectField}
            values={vacationTypeSelect?.map((el) => ({
              name: el.entryName,
              value: el.uuid,
            }))}
            label={`${t("global:vacation.vacationType")}*`}
            name="vacationType"
            data-cy="vacationType"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            disabled={update}
            component={BMXTextField}
            label={`${t("global:vacation.justification")}`}
            name="justification"
            data-cy="justification"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4 }}>
          <Field
            disabled={update}
            component={BMXDatePicker}
            label={`${t("global:vacation.absenceSince")}*`}
            name="absenceSince"
            data-cy="absenceSince"
          />
        </Col>
        <Col xs={{ span: 4 }}>
          <Field
            disabled={update}
            component={BMXDatePicker}
            label={`${t("global:vacation.absenceUntil")}*`}
            name="absenceUntil"
            data-cy="absenceUntil"
          />
        </Col>
        <Col xs={4}>
          <Field
            disabled={update}
            name="numberOfDays"
            label={`${t("global:vacation.numberOfDays")}*`}
            component={BMXTextField}
            data-cy="numberOfDays"
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Col>
      </Row>
      <Row>
        <Field
          disabled={update}
          component={BMXSelectField}
          values={workersSelect?.map((el) => ({
            name: `${el.firstName} ${el.lastName}`,
            value: el.uuid,
          }))}
          label={`${t("global:vacation.replacement")}`}
          name="replacement"
          data-cy="replacement"
        />
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            disabled={update}
            component={BMXTextField}
            label={`${t("global:vacation.comments")}`}
            name="comments"
            data-cy="comments"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            disabled={update}
            component={BMXSelectField}
            values={departmentsLeadersSelect?.map((el) => ({
              name: `${el.firstName} ${el.lastName}`,
              value: el.uuid,
            }))}
            label={`${t("global:vacation.responsible")}*`}
            name="responsible"
            data-cy="responsible"
          />
        </Col>
      </Row>
      <Row>
        <div className="mt-2">
          <span className="info-box">{`${t(
            "global:vacation.beforeAddInfo"
          )}`}</span>
        </div>
      </Row>
    </div>
  );
};

export default VacationFormStepOne;
