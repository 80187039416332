import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { useEffect, useState } from "react";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import "../ContactsForm.scss";

const ContactsFormStepOne = (props) => {
  const { t } = useTranslation();
  const { values } = props;
  const [isNipExists, setIsNipExists] = useState(false);
  const { nip } = values;

  const checkIsNipExistInDb = async (nip) => {
    const { payload } = await GlobalAuthFetch({
      path: `/contact/checkIsNipExists/${nip}`,
      method: "GET",
    });

    if (payload?.data) {
      setIsNipExists(payload.data);
    }
  };

  useEffect(() => {
    setIsNipExists(false);
    if (values?.nip?.length > 0) {
      values.nip = values.nip.replace(/\s+/g, "");
      values.nip = values.nip.replace(/[^a-zA-Z0-9]/g, "");
      if (nip.length >= 10) {
        checkIsNipExistInDb(nip);
      }
    }
    // eslint-disable-next-line
  }, [nip]);

  return (
    <div>
      <BMXSeparator title={t("global:contacts.informationAboutContact")} />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.name")}*`}
            name="name"
            data-cy="name"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.shortName")}`}
            name="shortName"
            data-cy="shortName"
          />
        </Col>
        <Col xs={2}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.contactId")}`}
            name="contactId"
            data-cy="contactId"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={5}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.nip")}`}
            name="nip"
            data-cy="nip"
          />
        </Col>
        <Col xs={4}>
          <Row>
            {isNipExists && (
              <div className="nip-warning">
                <span>{`${t("global:contacts.nipExists")}`}</span>
              </div>
            )}
          </Row>
        </Col>
      </Row>
      <BMXSeparator title={t("global:contacts.informationDetails")} />
      <Row>
        <Col xs={3}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.state")}`}
            name="state"
            data-cy="state"
          />
        </Col>
        <Col xs={3}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.city")}*`}
            name="city"
            data-cy="city"
          />
        </Col>
        <Col xs={3}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.postCode")}*`}
            name="postCode"
            data-cy="postCode"
          />
        </Col>
        <Col xs={3}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.post")}`}
            name="post"
            data-cy="post"
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5%" }}>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.street")}*`}
            name="street"
            data-cy="street"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.houseNumber")}*`}
            name="houseNumber"
            data-cy="houseNumber"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.apartmentNumber")}`}
            name="apartmentNumber"
            data-cy="apartmentNumber"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContactsFormStepOne;
