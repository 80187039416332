import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { submit, validate } from "./SecretariatCorespondenceContactFormSubmit";
import { Col, Row } from "react-bootstrap";
import "./SecretariatCorespondenceContactForm.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { updateDashboardByName } from "../../../../Utils/Sockets";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import BMXTextField from "../../../../Components/Inputs/BMXTextField/BMXTextField";

import { Autocomplete, Icon, IconButton } from "@mui/material";

const SecretariatCorespondenceContactForm = (props) => {
  const { contactValues: initialValues, formName, subject } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const REGISTRY_NAME = "contact";
  const [options, setOptions] = useState([]);

  const getData = async (searchTerm) => {
    if (searchTerm?.length > 0) {
      const data = GlobalAuthFetch({
        path: `/contact/findByName/${searchTerm}`,
      });
      const [contactListPayload] = await Promise.all([data]);
      const contactList = contactListPayload?.payload?.data?.map((contact) => {
        return {
          uuid: contact.uuid,
          value: `${contact.name}`,
          ...contact,
        };
      });

      if (contactList?.length > 0) {
        setOptions(contactList);
        return contactList;
      } else {
        setOptions([]);
        return [];
      }
    }
  };

  // TODO DOPISAĆ WCZYTANIE DANYCH NA OTWARCIE MODALA

  const [valueContact, setValueContact] = useState(initialValues?.contact);
  const [valueBranch, setValueBranch] = useState(initialValues?.branch);
  const [valueRepresentative, setValueRepresentative] = useState(
    initialValues?.representative
  );

  const loadData = async () => {
    // FAKE LOAD DATA
  };

  const getDataAndSetContact = async () => {
    const list = await getData(valueContact?.name);
    const uuid = valueContact.uuid;
    const contact = list.find((e) => e.uuid === uuid);
    if (contact) {
      setValueContact(contact);
    }
  };

  useEffect(() => {
    getDataAndSetContact();
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={{
        contact: initialValues?.contact,
        branch: initialValues?.branch,
        representative: initialValues?.representative,
        subject: subject,
      }}
      onSubmit={(val) => submit(val, { ...props })}
      validate={validate}
    >
      {({ handleSubmit, values, form }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="SecretariatCorespondenceContactForm justify-content-around"
            id={formName}
          >
            <Row>
              <Col xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Autocomplete
                    options={options || []}
                    value={valueContact}
                    fullWidth
                    getOptionLabel={(option) => option?.name?.toString()}
                    onChange={(event, newValue) => {
                      setValueContact(newValue);
                      setValueBranch(null);
                      setValueRepresentative(null);
                      form.change("contact", newValue); // Update the form's state
                      form.change("branch", null);
                      form.change("representative", null);
                    }}
                    renderInput={(params) => (
                      <Field
                        {...params}
                        data-cy="contact"
                        currentValues={values}
                        component={BMXTextField}
                        label={`${t(
                          "global:secretariatCorespondenceContact.contact"
                        )}`}
                        name="contact"
                        inputProps={{
                          ...params.inputProps,
                          onChange: (event) => {
                            params.inputProps.onChange(event); // Call the default onChange function
                            getData(event.target.value);
                          },
                        }}
                      />
                    )}
                  />

                  <div
                    style={{
                      display: "flex",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        const updateTableRegistry = () =>
                          updateDashboardByName(REGISTRY_NAME);
                        dispatch(
                          show("ContactsModal", {
                            updateTableRegistry,
                            header: t("global:contacts.add"),
                            loadData,
                          })
                        );
                      }}
                      size="small"
                    >
                      <Icon style={{ fontSize: "0.938rem" }}>add</Icon>
                    </IconButton>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Autocomplete
                    options={
                      valueContact?.branches ? valueContact.branches : []
                    }
                    value={valueBranch}
                    getOptionLabel={(option) => `${option?.name}, ${option.city || ""}, ${option.address || ""} `}
                    fullWidth
                    onChange={(event, newValue) => {
                      setValueBranch(newValue);
                      form.change("branch", newValue); // Update the form's state
                    }}
                    renderInput={(params) => (
                      <Field
                        {...params}
                        data-cy="branch"
                        currentValues={values}
                        component={BMXTextField}
                        label={`${t(
                          "global:secretariatCorespondenceContact.branch"
                        )}`}
                        name="branch"
                        inputProps={{
                          ...params.inputProps,
                          onChange: (event) => {
                            params.inputProps.onChange(event); // Wywołaj domyślną funkcję onChange
                          },
                        }}
                      />
                    )}
                  />
                  {values?.contact && (
                    <IconButton
                      style={{
                        display: "flex",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        const updateSingleRegistry = () =>
                          updateDashboardByName(
                            REGISTRY_NAME,
                            values?.contact?.uuid
                          );

                        dispatch(
                          show("BranchesModal", {
                            loadData: async () => {
                              await getDataAndSetContact();
                            },
                            header: t("global:contact.branchAdd"),
                            updateSingleRegistry,
                            uuid: values?.contact?.uuid,
                          })
                        );
                      }}
                      size="small"
                    >
                      <Icon style={{ fontSize: "0.938rem" }}>add</Icon>
                    </IconButton>
                  )}
                </div>
              </Col>
            </Row>

            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  options={
                    valueContact?.representatives
                      ? valueContact.representatives
                      : []
                  }
                  value={valueRepresentative}
                  getOptionLabel={(option) =>
                    `${option?.name || ""} ${option.lastname || ""}`
                  }
                  fullWidth
                  onChange={(event, newValue) => {
                    setValueRepresentative(newValue);
                    form.change("representative", newValue); // Update the form's state
                  }}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      data-cy="representative"
                      currentValues={values}
                      component={BMXTextField}
                      label={`${t(
                        "global:secretariatCorespondenceContact.representative"
                      )}`}
                      name="representative"
                      inputProps={{
                        ...params.inputProps,
                        onChange: (event) => {
                          params.inputProps.onChange(event); // Wywołaj domyślną funkcję onChange
                        },
                      }}
                    />
                  )}
                />
                {values?.contact && (
                  <IconButton
                    style={{
                      display: "flex",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    onClick={() => {
                      const updateSingleRegistry = () =>
                        updateDashboardByName(
                          REGISTRY_NAME,
                          values?.contact?.uuid
                        );

                      dispatch(
                        show("RepresentativesModal", {
                          loadData: async () => {
                            await getDataAndSetContact();
                          },
                          header: t("global:contact.representativeAdd"),
                          updateSingleRegistry,
                          uuid: values?.contact?.uuid,
                        })
                      );
                    }}
                    size="small"
                  >
                    <Icon style={{ fontSize: "0.938rem" }}>add</Icon>
                  </IconButton>
                )}
              </div>
            </Col>
            <Row>
              <Col xs={12}>
                <Field
                  data-cy="subject"
                  component={BMXTextField}
                  label={`${t("global:secreateryCorespondence.subjectv2")}`}
                  name="subject"
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Form>
  );
};

export default SecretariatCorespondenceContactForm;
