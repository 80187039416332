import i18n from "../../../../Utils/i18next/i18next";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  if (values?.vacationType?.value)
    set(data, "vacationType.uuid", values?.vacationType?.value);

  if (values?.responsible?.value) {
    set(data, "responsible.uuid", values?.responsible?.value);
    delete data.responsible.value;
    delete data.responsible.name;
  }

  if (values?.replacement?.value) {
    set(data, "replacement.uuid", values?.replacement?.value);
    delete data.replacement.value;
    delete data.replacement.name;
  }

  if (values?.decision?.value) {
    set(data, "decision", values?.decision?.value);
    delete data.decision.name;
  }

  if (values?.hrdecision?.value) {
    set(data, "hrdecision", values?.hrdecision?.value);
    delete data.hrdecision.name;
  }

  let payload;

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/vacation/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values.hrdecision) {
    errorsStep0.hrdecision = t("global:vacation.errors.HRDecision");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
