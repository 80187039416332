import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { updateDashboardByName } from "../../../../../Utils/Sockets";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXAutocomplete from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import BMXAsynchronous from "../../../../../Components/Inputs/BMXAsynchronous/BMXAsynchronous";
const DeviceFormStepOne = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { form, values, update, initialValues } = props;

  const [contacts, setContacts] = useState([]);
  const [representatives, setRepresentatives] = useState([]);
  const [branches, setBranches] = useState([]);
  const [printerModel, setPrinterModel] = useState([]);
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [deviceIsOnActiveContract, setDeviceIsOnActiveContract] = useState(false);


  const loadData = async () => {
    const fetchPrinterModel = GlobalAuthFetch({ path: "/printermodel/all" });
    const [resPrinterModel] = await Promise.all([fetchPrinterModel]);

    if(initialValues?.uuid){
      const resCheckDeviceIsOnActiveContract = await GlobalAuthFetch({ path: `/rentcontract/checkDeviceIsOnActiveContract/${initialValues?.uuid}` });
      setDeviceIsOnActiveContract(resCheckDeviceIsOnActiveContract?.payload?.data)
    }

    setPrinterModel(
      resPrinterModel?.payload?.data
        ?.map((el) => ({
          uuid: el.uuid,
          options: el.options,
          deviceType: el.deviceType,
          manufacturer: el.manufacturer,
          label: el.modelName,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  };

  useEffect(() => {
    setOptionsSelect(
      values?.printerModel?.options?.map((el) => ({
        value: el.uuid,
        name: el.entryName,
      })) || []
    );
    // eslint-disable-next-line
  }, [values?.printerModel?.uuid]);

  useEffect(() => {
    const fetchData = async () => {
      const deviceOwnerFetch = GlobalAuthFetch({
        path: "/dictionary/deviceOwner/fieldName",
      });
      const serviceBaseFetch = GlobalAuthFetch({
        path: "/dictionary/serviceBase/fieldName",
      });
      const servicePriorityFetch = GlobalAuthFetch({
        path: "/dictionary/servicePriority/fieldName",
      });

      const [deviceOwnerPayload, serviceBasePayload, servicePriorityPayload] =
        await Promise.all([
          deviceOwnerFetch,
          serviceBaseFetch,
          servicePriorityFetch,
        ]);
      setdeviceOwnerSelect(
        deviceOwnerPayload?.payload?.data?.dictionaryEntries
          ?.sort((a, b) => a.entryName.localeCompare(b.entryName))
          ?.map((el) => ({
            name: el.entryName,
            value: el.uuid,
          })) || []
      );
      setserviceBaseSelect(
        serviceBasePayload?.payload?.data?.dictionaryEntries
          ?.sort((a, b) => a.entryName.localeCompare(b.entryName))
          ?.map((el) => ({
            name: el.entryName,
            value: el.uuid,
          })) || []
      );
      setservicePrioritySelect(
        servicePriorityPayload?.payload?.data?.dictionaryEntries
          ?.sort((a, b) => a.entryName.localeCompare(b.entryName))
          ?.map((el) => ({
            name: el.entryName,
            value: el.uuid,
          })) || []
      );
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const loadRepresentative = (contact) => {
    const selectedRepresentatives = contact?.representatives?.map(
      (representative) => ({
        uuid: representative.uuid,
        label: `${representative.name || ""} ${representative.lastname || ""}`,
      })
    );
    if (!selectedRepresentatives) {
      setRepresentatives([]);
      form.change("representative", null);
      return;
    }
    setRepresentatives(
      selectedRepresentatives?.sort((a, b) => a.label.localeCompare(b.label)) ||
        []
    );
    form.change(
      "representative",
      selectedRepresentatives.find(
        (el) => el.uuid === values?.representative?.uuid
      ) || null
    );
  };
  const loadBranches = (contact) => {
    const selectedBranches = contact?.branches?.map((branch) => ({
      uuid: branch.uuid,
      label: `${branch.name || ""}, ${branch.city || ""}, ${branch.address || ""} `,
    }));
    if (!selectedBranches) {
      setBranches([]);
      form.change("branche", null);
      return;
    }
    setBranches(
      selectedBranches?.sort((a, b) => a.label.localeCompare(b.label)) || []
    );
    form.change(
      "branche",
      selectedBranches.find((el) => el.uuid === values?.branche?.uuid) || null
    );
  };

  const [deviceOwner, setdeviceOwnerSelect] = useState([]);
  const [serviceBaseSelect, setserviceBaseSelect] = useState([]);
  const [servicePrioritySelect, setservicePrioritySelect] = useState([]);

  useEffect(() => {
    const selectedContact = contacts.find(
      (el) => el.uuid === values?.client?.uuid
    );
    loadRepresentative(selectedContact);
    loadBranches(selectedContact);
    // eslint-disable-next-line
  }, [values.client?.uuid, contacts]);

  useEffect(() => {
    if (
      values?.printerModel?.uuid === props?.initialValues?.printerModel?.uuid
    ) {
      return;
    }

    form.change("deviceOptions", null);
    form.change("manufacturer", values?.printerModel?.manufacturer?.entryName);
    form.change("deviceType", values?.printerModel?.deviceType?.entryName);
    // eslint-disable-next-line
  }, [values.printerModel?.uuid]);

  useEffect(() => {
    // if (values?.client?.uuid === props?.initialValues?.client?.uuid) return;
    // form.change("branch", null);
    // form.change("addressFloor", null);
    // form.change("clientRepresentative", null);
    // eslint-disable-next-line
  }, [values.client?.uuid]);
  useEffect(() => {
    form.change("manufacturer", values?.printerModel?.manufacturer?.entryName);
    form.change("deviceType", values?.printerModel?.deviceType?.entryName);
    // eslint-disable-next-line
  }, [values.printerModel?.uuid]);

  useEffect(() => {
    loadData();

    // eslint-disable-next-line
  }, []);

  const lastContract = values?.deviceOnContract?.at(-1)?.existOnContractNo;

  const isActiveContract =
    !lastContract?.archive && lastContract?.status?.status === "ACTIVE";

  const getData = async (searchTerm) => {
    if (searchTerm?.length > 0) {
      const data = GlobalAuthFetch({
        path: `/contact/findByName/${searchTerm}`,
      });
      const [contactListPayload] = await Promise.all([data]);
      const contactList = contactListPayload?.payload?.data?.map((contact) => {
        return {
          uuid: contact.uuid,
          value: `${contact.name}`,
          ...contact,
        };
      });

      if (contactList?.length > 0) {
        setContacts(contactList);
        return contactList;
      } else {
        setContacts([]);
        return [];
      }
    }
  };

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <Row>
        <Col xs={8}>
          <Row>
            <Col xs={{ span: 4 }}>
              <Field
                name="serialNumber"
                label={`${t("global:device.serialNumber")}`}
                component={BMXTextField}
                data-cy="serialNumber"
                disabled={update}
              />
            </Col>
          </Row>
          <BMXSeparator title={t("global:device.device")} />
          <Row>
            <Col xs={{ span: 4 }}>
              <Field
                component={BMXAutocomplete}
                label={`${t("global:device.printerModel")}`}
                name="printerModel"
                data-cy="printerModel"
                options={printerModel}
                currentValues={values}
                afterIcon="add"
                afterIconSize="13px"
                afterIconClick={() => {
                  const updateTableRegistry = () =>
                    updateDashboardByName("printerModel");
                  dispatch(
                    show("PrinterModelModal", {
                      updateTableRegistry,
                      header: t("global:printerModel.add"),
                      loadData,
                    })
                  );
                }}
              />
            </Col>
            <Col xs={{ span: 4 }}>
              <Field
                name="manufacturer"
                label={`${t("global:device.manufacturer")}`}
                component={BMXTextField}
                data-cy="manufacturer"
                disabled={true}
              />
            </Col>
            <Col xs={{ span: 4 }}>
              <Field
                disabled={true}
                name="deviceType"
                label={`${t("global:device.deviceType")}`}
                component={BMXTextField}
                data-cy="deviceType"
              />
            </Col>
          </Row>
          <Row>
            <Row>
              <Col xs={12}>
                <Field
                  name="additionalDescription"
                  label={`${t("global:device.additionalDescription")}`}
                  component={BMXTextField}
                  data-cy="additionalDescription"
                  maxRows={4}
                  multiline={true}
                />
              </Col>
            </Row>
          </Row>

          <Row>
            <Col xs={4}>
              <Field
                component={BMXSelectField}
                values={optionsSelect}
                selectedValues={values}
                initialValues={{
                  deviceOptions: initialValues?.deviceOptions?.map((e) => ({
                    value: e.uuid,
                    name: e.entryName,
                  })),
                }}
                multiple
                displayEmpty
                label={`${t("global:device.deviceOption")}`}
                name="deviceOptions"
                data-cy="deviceOptions"
                renderValue={(el) => {
                  return el.map((e) => e.name).join(", ");
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={4}>
              {!isActiveContract ? (
                <Field
                  component={BMXSelectField}
                  values={serviceBaseSelect}
                  label={`${t("global:device.serviceBase")}`}
                  name="serviceBase"
                  disabled={isActiveContract}
                  data-cy="serviceBase"
                />
              ) : null}
            </Col>
            <Col xs={4}>
              {!isActiveContract ? (
                <Field
                  component={BMXSelectField}
                  values={servicePrioritySelect}
                  label={`${t("global:device.servicePriority")}`}
                  disabled={isActiveContract}
                  name="servicePriority"
                  data-cy="servicePriority"
                />
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 4 }}>
              <Field
                component={BMXAsynchronous}
                label={`${t("global:device.supplier")}`}
                name="supplier"
                data-cy="supplier"
                options={contacts}
                currentValues={values}
                getOptionLabel={(option) => {
                  if (option.name) return option.name;
                  return option;
                }}
                getData={getData}
                afterIcon="add"
                afterIconSize="13px"
                afterIconClick={() => {
                  const updateTableRegistry = () =>
                    updateDashboardByName("contact");
                  dispatch(
                    show("ContactsModal", {
                      updateTableRegistry,
                      header: t("global:contacts.add"),
                      loadData,
                    })
                  );
                }}
              />
            </Col>

            <Col xs={4}>
              <Field
                component={BMXSelectField}
                values={deviceOwner}
                label={`${t("global:device.deviceOwner")}`}
                name="deviceOwner"
                data-cy="deviceOwner"
              />
            </Col>
            <Col xs={2}>
              <Field
                name="deviceInvoice"
                label={`${t("global:device.deviceInvoice")}`}
                component={BMXTextField}
                data-cy="deviceInvoice"
              />
            </Col>
            <Col xs={2}>
              <Field
                name="deviceDateOfBuy"
                data-cy="deviceDateOfBuy"
                component={BMXDatePicker}
                label={t("global:device.deviceDateOfBuy")}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Field
                name="deviceWarrantyFrom"
                data-cy="deviceWarrantyFrom"
                component={BMXDatePicker}
                label={t("global:device.deviceWarrantyFrom")}
              />
            </Col>
            <Col xs={4}>
              <Field
                name="deviceWarrantyTo"
                data-cy="deviceWarrantyTo"
                component={BMXDatePicker}
                label={t("global:device.deviceWarrantyTo")}
              />
            </Col>
            <Col xs={4}>
              <Field
                name="partsWarrantyTo"
                data-cy="partsWarrantyTo"
                component={BMXDatePicker}
                label={t("global:device.partsWarrantyTo")}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Field
                fieldtype="checkbox"
                component={BMXTextField}
                name="deviceMonitoring"
                label={t("global:device.deviceMonitoring")}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={4} style={{ marginTop: "75px" }}>
          <BMXSeparator title={t("global:device.client")} />

          <Row>
            <Col xs={{ span: 12 }}>
              <Field
              disabled={deviceIsOnActiveContract}
                currentValues={values}
                className="client"
                component={BMXAsynchronous}
                getOptionLabel={(option) => {
                  if (option.name) return option.name;
                  return option;
                }}
                label={`${t("global:device.client")}`}
                name="client"
                options={contacts}
                // disabled={update}
                getData={getData}
                data-cy="client"
                disablePortal={true}
                onClose={() => {
                  form.change("branch", null);
                  form.change("representative", null);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                component={BMXAutocomplete}
                label={`${t("global:device.branch")}`}
                name="branch"
                data-cy="branch"
                options={branches}
                disabled={!values.client ||  deviceIsOnActiveContract}
                currentValues={values}
                // getOptionLabel={(option) => {
                //   if (option.name) return option.name;
                //   return option;
                // }}
                afterIcon="add"
                afterIconSize="13px"
                afterIconClick={() => {
                  const contactUuid =
                    values?.client?.uuid || values?.client?.value;
                  const updateSingleRegistry = () => {
                    updateDashboardByName("contact", contactUuid);
                  };
                  dispatch(
                    show("BranchesModal", {
                      loadData: async () => {
                        await getData(values?.client?.name);
                      },
                      header: t("global:contact.brancheAdd"),
                      updateSingleRegistry,
                      uuid: contactUuid,
                    })
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                name="addressFloor"
                label={`${t("global:device.addressFloor")}`}
                component={BMXTextField}
                data-cy="addressFloor"
                disabled={deviceIsOnActiveContract}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                component={BMXAutocomplete}
                label={`${t("global:device.clientRepresentative")}`}
                name="clientRepresentative"
                data-cy="clientRepresentative"
                options={representatives}
                disabled={!values.client ||  deviceIsOnActiveContract}
                currentValues={values}
                // getOptionLabel={(option) => {
                //   if (option.name) return option.name;
                //   return option;
                // }}
                afterIcon="add"
                afterIconSize="13px"
                afterIconClick={() => {
                  const contactUuid =
                    values?.client?.uuid || values?.client?.value;
                  const updateSingleRegistry = () => {
                    updateDashboardByName("contact", contactUuid);
                  };
                  dispatch(
                    show("RepresentativesModal", {
                      loadData: async () => {
                        await getData(values?.client?.name);
                      },
                      header: t("global:contact.representativeAdd"),
                      updateSingleRegistry,
                      uuid: contactUuid,
                    })
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DeviceFormStepOne;
