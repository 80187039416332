import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { vacationReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry } =
    props;
  const data = cloneDeep(values);

  if (values?.vacationType?.value)
    set(data, "vacationType.uuid", values?.vacationType?.value);

  if (values?.responsible?.value) {
    set(data, "responsible.uuid", values?.responsible?.value);
    delete data.responsible.value;
    delete data.responsible.name;
  }

  if (values?.replacement?.value) {
    set(data, "replacement.uuid", values?.replacement?.value);
    delete data.replacement.value;
    delete data.replacement.name;
  }

  let payload;

  if (!update) {
    data.status = vacationReducer?.availableStatuses.find(
      (el) => !!el.isSelected
    );

    const res = await GlobalAuthFetch({
      path: `/vacation`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  // if (update) {
  //   const res = await GlobalAuthFetch({
  //     path: `/offer/${uuid}`,
  //     method: "PATCH",
  //     data,
  //   });
  //   payload = res.payload;
  // }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values.vacationType) {
    errorsStep0.vacationType = t("global:vacation.errors.vacationType");
  }

  if (!values.absenceSince) {
    errorsStep0.absenceSince = t("global:vacation.errors.absenceSince");
  }

  if (!values.absenceUntil) {
    errorsStep0.absenceUntil = t("global:vacation.errors.absenceUntil");
  }

  if (!values.numberOfDays) {
    errorsStep0.numberOfDays = t("global:vacation.errors.numberOfDays");
  }

  if (!values.numberOfDays) {
    errorsStep0.numberOfDays = t("global:vacation.errors.numberOfDays");
  }

  if (!values.responsible) {
    errorsStep0.responsible = t("global:vacation.errors.responsible");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
